import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { ToastrService } from 'ngx-toastr';
import { AESEncryptDecryptService } from 'src/app/services/aesencrypt-decrypt.service';
import { CommissionService } from 'src/app/services/commission/commission.service';
import { NgForm } from '@angular/forms';

@Component({  
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  managerId: number | null = null;
  bookingData: any
  private apiType: string = 'save_slots';
  storedStaffList: any[] = [];
  storedStaff: any;
  isStaffModalOpen = false;
  customer_data: any;

  constructor(
    private toastr: ToastrService,
    private commission_serv: CommissionService,
    private auth: AESEncryptDecryptService,
    private route: ActivatedRoute, private router: Router,
    private custumer: CustomerService
  ) { }

  ngOnInit(): void {
    this.init_form()
  }

  user:string;
  init_form() {
    //  this.user = sessionStorage.getItem('username');
    // console.log(this.user,'anshu')
    const storedData = sessionStorage.getItem('staffList');
    this.storedStaffList = storedData ? JSON.parse(storedData) : [];
    this.storedStaff = this.storedStaffList[0];
    console.log(this.storedStaff.id,'jfsed');
    
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.managerId = id ? +id : null;
    })
    if (this.managerId) {
      this.custumer.customer_list(this.managerId).subscribe(
        (data) => {
          this.bookingData = data || [];
          console.log('Booking data:', this.bookingData.data.records);
        },
        (error) => {
          console.error('Error fetching booking data:', error);
        }
      );
    } else {
      console.error('Manager ID not found.');
    }
  

  }


  inspection_details(data:any){
    console.log(data,'anshu');
    sessionStorage.setItem('userid',data)
    
  }
  getStaffForManager(managerId: number) {
    console.log(managerId,'hjdsfj')
    this.customer_data = managerId;
    this.isStaffModalOpen = true;
  }
  closeStaffModal() {
    this.isStaffModalOpen = false;
  }

  onSubmit(data: any): void {
    const formData = {
      id: this.customer_data.id,
      customer_id: this.customer_data.customer_id,
      staff_id: this.storedStaff.staff_id
    };
    this.commission_serv.apiCall(this.apiType, formData).subscribe(
      (res: any) => {
        if (res.success === true) {
          this.toastr.success(res.message, "Success");
          this.isStaffModalOpen = false;
          this.init_form()
          } else {
          this.toastr.error(res.message, "Error");
        }
      });
  }

}