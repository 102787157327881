import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AESEncryptDecryptService } from 'src/app/services/aesencrypt-decrypt.service';
import { CommissionService } from 'src/app/services/commission/commission.service';
import { CustomerService } from 'src/app/services/customer/customer.service';

@Component({
  selector: 'app-customer-inspection-details',
  templateUrl: './customer-inspection-details.component.html',
  styleUrls: ['./customer-inspection-details.component.css']
})
export class CustomerInspectionDetailsComponent implements OnInit {
  images: string[] = [];
  inspection_list: any;
  videos: string[] = [];
  imageNames: string[] = []; // New array for image names


  
  constructor(
    private toastr: ToastrService,
    private commission_serv: CommissionService,
    private auth: AESEncryptDecryptService,
    private route: ActivatedRoute, private router: Router,
    private customer: CustomerService
  ) { }
  questions: { question: string; answer: string }[] = [];

  ngOnInit() {
   let id =  sessionStorage.getItem('userid')
    this.customer.inspected_customer_list(id).subscribe(
      (res) => {
        this.inspection_list = res || [];
        console.log(this.inspection_list.data.records[0].inspectionImg, 'anshu');
        const inspectionImg = this.inspection_list.data.records[0].inspectionImg;

        const imageLabels: { [key: string]: string } = {
          pic1: 'Front',
          pic2: 'Rear',
          pic3: 'Right Side',
          pic4: 'Left Side',
          pic5: 'Trunk',
          pic6: 'Silencer',
          pic7: 'Steering',
          pic8: 'Engine',
        };

        const BaseURL = 'https://adminapi.redinsure.redianglobal.com/'
        this.questions = [
          { question: 'Tire Condition Good?', answer: this.inspection_list.data.records[0].tire_condition },
          { question: 'Brake Performance Good?', answer: this.inspection_list.data.records[0].tire_condition },
          { question: 'Engine Condition Good?', answer: this.inspection_list.data.records[0].engine_condition },
          { question: 'Battery Health Good?', answer: this.inspection_list.data.records[0].battery_health },
          { question: 'Inspected by staff?', answer: this.inspection_list.data.records[0].is_inspected ? 'Yes' : 'No' },
          { question: 'Is Approved?', answer: this.inspection_list.data.records[0].is_approve ? 'Yes' : 'No' },
        ];
        // Populate images and imageNames arrays
        this.images = Object.keys(inspectionImg)
          .filter((key) => key.startsWith('pic')) // Filter for "pic" keys
          .map((key) => BaseURL + inspectionImg[key]); // Convert to full URLs
  
        this.imageNames = Object.keys(inspectionImg)
          .filter((key) => key.startsWith('pic')) // Filter for "pic" keys
          .map((key) => imageLabels[key] || 'Unknown'); // Map to labels or default to "Unknown"
  
        console.log(this.images, 'Image URLs');
        console.log(this.imageNames, 'Image Names');
  
        if (inspectionImg.video) {
          this.videos = [BaseURL + inspectionImg.video];
        }
  
        console.log(this.videos, 'Video URLs');
      },
    (error) => {
      console.error('Error fetching booking data:', error);
    }
  );
}


  approve(){ 
    console.log(this.inspection_list.data.records[0].id);
    const id = this.inspection_list.data.records[0].id;
    this.customer.approve_by_staff(id).subscribe(
      (response) => {
        console.log('API Response:', response);
        this.toastr.success('Record has been saved successfully!!',"Success")
      },
      (error) => {
        console.error('API Error:', error);
      }
    );
  } 

  


}
