import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddComponent } from './components/add/add.component';
import { ListComponent } from './components/list/list.component';
import {inspectionRoutingModule} from './inspection-routing.module';
import { FormsModule } from '@angular/forms';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { EditComponent } from './components/edit/edit.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxLoadingModule} from 'ngx-loading';
import { SharedModule } from '../../shared/shared.module';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { RouterModule } from '@angular/router';



import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import { CalendarComponent } from './components/calendar/calendar/calendar.component';
import { BookingComponent } from './booking/booking.component';
import { BookingListComponent } from './components/booking-list/booking-list.component';
import { CustomerInspectionDetailsComponent } from './customer-inspection-details/customer-inspection-details.component';

FullCalendarModule.registerPlugins([
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin
  ])

  
@NgModule({
declarations: [
AddComponent,
ListComponent,
EditComponent,
CalendarComponent,
BookingComponent,
BookingListComponent,
CustomerInspectionDetailsComponent
],
imports: [
CommonModule,
inspectionRoutingModule,
FormsModule,
GridModule,
NgxPaginationModule,
Ng2SearchPipeModule,
NgxLoadingModule,
SharedModule,
DigitOnlyModule,
OwlDateTimeModule,

RouterModule,
FullCalendarModule,
OwlNativeDateTimeModule
],
providers: [PageService, SortService, FilterService, GroupService],
bootstrap: [ListComponent]
})
export class InspectionModule { }