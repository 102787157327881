import { Component, Input } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import { Router } from '@angular/router';
import { EventInput } from '@fullcalendar/angular';

let eventGuid = 0;
const TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent {

  INITIAL_EVENTS: EventInput[] = [
    {
      id: createEventId(),
      title: 'Timed event',
      start: TODAY_STR + 'T12:00:00'
    }
  ]

  constructor(private router: Router){}
  calData: any[] = []; // Store the passed data
  calendarVisible = false;
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'dayGridMonth',
    initialEvents: [], // Initially empty; will be populated dynamically
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this)
  };
  currentEvents: EventApi[] = [];

  ngOnInit() {
    const storedData = localStorage.getItem('calData');
    this.calData = storedData ? JSON.parse(storedData) : [];
    console.log('Calendar Data:', this.calData);
  
    // Prepare events array
    const arr = this.calData.map(x => ({
      id: createEventId(),
      title: 'Booked Slot',
      start: new Date(x.date).toISOString().replace(/T.*$/, '') + `T${x.slot}:00`
    }));
  
    // Update calendar options
    this.calendarOptions = {
      ...this.calendarOptions, // Spread existing options to preserve other settings
      initialEvents: arr       // Set the dynamically generated events
    };
  
    this.calendarVisible = true;
  }
  

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
}
